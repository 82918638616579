export default function LogoBlack(){
  return(
    <svg id="Ebene_2" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" width="77.696" height="74.588" viewBox="0 0 77.696 74.588">
      <g id="Ebene_1" data-name="Ebene 1">
        <path id="Pfad_1" data-name="Pfad 1" d="M244.881,201.847c1.642,8.426,10.785,17.73,13.97,18.578A249.477,249.477,0,0,1,248.48,178.58C243.622,187.332,243.133,192.878,244.881,201.847Z" transform="translate(-236.006 -172.759)" fill="#1a1a1a"/>
        <path id="Pfad_2" data-name="Pfad 2" d="M1788.843,0s10.741,16.063-2.553,30.068c1.186.508,6.168,5.388,2.027,13.573,3.1-3.359,8.854-9.733,9.446-20.315C1798.532,9.581,1789.383.56,1788.843,0Z" transform="translate(-1728.064)" fill="#1a1a1a"/>
        <path id="Pfad_3" data-name="Pfad 3" d="M1324.47,1110.809l1.489,5.076s3.683-.986,2.66-3.752C1327.2,1108.735,1324.47,1110.809,1324.47,1110.809Z" transform="translate(-1281.297 -1073.995)" fill="#1a1a1a"/>
        <path id="Pfad_4" data-name="Pfad 4" d="M1206.565,640.849c-.969-3.959-4.726-2.859-5.655-2.47,0,0,.768,5.032,1.891,7.3C1204.091,645.669,1207.3,645.009,1206.565,640.849Z" transform="translate(-1161.765 -617.145)" fill="#1a1a1a"/>
        <path id="Pfad_5" data-name="Pfad 5" d="M3.031,2021.158q-.924-.68-1.091-.839a1.949,1.949,0,0,1-.419-.562,1.282,1.282,0,0,1-.114-.531,1.009,1.009,0,0,1,.324-.76,1.166,1.166,0,0,1,.835-.309,1.41,1.41,0,0,1,.732.2,3.328,3.328,0,0,1,.812.829l.689-.52a3.842,3.842,0,0,0-1.1-1.084,2.23,2.23,0,0,0-1.117-.293,2.151,2.151,0,0,0-1.041.261,1.889,1.889,0,0,0-.744.712,1.952,1.952,0,0,0-.264,1,2.088,2.088,0,0,0,.448,1.286A7.29,7.29,0,0,0,2.3,2021.7a6.886,6.886,0,0,1,1.447,1.349,1.43,1.43,0,0,1,.287.83,1.369,1.369,0,0,1-.2.7,1.4,1.4,0,0,1-.568.529,1.692,1.692,0,0,1-.8.2q-.993,0-1.751-1.389l-.718.428a3.758,3.758,0,0,0,1.154,1.369,2.5,2.5,0,0,0,1.4.408,2.346,2.346,0,0,0,1.695-.675,2.141,2.141,0,0,0,.7-1.59,2.3,2.3,0,0,0-.408-1.263A6.147,6.147,0,0,0,3.031,2021.158Z" transform="translate(0 -1951.532)" fill="#1a1a1a"/>
        <path id="Pfad_6" data-name="Pfad 6" d="M333.432,2027.219l-2.161-3.469H330.3l2.727,4.391v4.019h.832v-4.019l2.675-4.391h-.976Z" transform="translate(-319.534 -1957.784)" fill="#1a1a1a"/>
        <path id="Pfad_7" data-name="Pfad 7" d="M684.684,2021.187q-.925-.68-1.091-.839a1.977,1.977,0,0,1-.419-.561,1.281,1.281,0,0,1-.114-.532,1.011,1.011,0,0,1,.324-.76,1.167,1.167,0,0,1,.835-.309,1.407,1.407,0,0,1,.731.2,3.313,3.313,0,0,1,.813.829l.689-.519a3.827,3.827,0,0,0-1.1-1.084,2.227,2.227,0,0,0-1.117-.293,2.155,2.155,0,0,0-1.043.261,1.882,1.882,0,0,0-.743.712,1.944,1.944,0,0,0-.264,1,2.093,2.093,0,0,0,.448,1.286,7.333,7.333,0,0,0,1.315,1.15,6.886,6.886,0,0,1,1.447,1.348,1.432,1.432,0,0,1,.287.83,1.377,1.377,0,0,1-.2.7,1.409,1.409,0,0,1-.57.529,1.684,1.684,0,0,1-.8.2q-.993,0-1.751-1.389l-.717.428a3.755,3.755,0,0,0,1.154,1.369,2.5,2.5,0,0,0,1.395.408,2.351,2.351,0,0,0,1.7-.675,2.137,2.137,0,0,0,.7-1.59,2.307,2.307,0,0,0-.408-1.263A6.13,6.13,0,0,0,684.684,2021.187Z" transform="translate(-659.431 -1951.562)" fill="#1a1a1a"/>
        <path id="Pfad_8" data-name="Pfad 8" d="M1006.69,2024.574h1.884v7.585h.861v-7.585h1.883v-.824h-4.628Z" transform="translate(-973.876 -1957.784)" fill="#1a1a1a"/>
        <path id="Pfad_9" data-name="Pfad 9" d="M1330.46,2032.159h4.806v-.823H1331.3v-3.3h3.962v-.823H1331.3v-2.635h4v-.824h-4.841Z" transform="translate(-1287.092 -1957.784)" fill="#1a1a1a"/>
        <path id="Pfad_10" data-name="Pfad 10" d="M1664.331,2023.75l-3.4,6.9-3.433-6.9h-.138l-1.209,8.409h.815l.83-6.06,3.021,6.06h.216l2.985-6.014.834,6.014h.828l-1.215-8.409Z" transform="translate(-1602.166 -1957.784)" fill="#1a1a1a"/>
        <path id="Pfad_11" data-name="Pfad 11" d="M2226.332,2028.43a2.639,2.639,0,0,0-1.125-.9,2.216,2.216,0,0,0,.663-.736,1.849,1.849,0,0,0,.2-.871,1.947,1.947,0,0,0-.449-1.281,2.269,2.269,0,0,0-1.206-.744,7.007,7.007,0,0,0-1.691-.149H2221.4v8.41h2.369a3.655,3.655,0,0,0,1.6-.288,2.422,2.422,0,0,0,1.315-2.163A2.272,2.272,0,0,0,2226.332,2028.43Zm-3.324-3.153h.415a1.243,1.243,0,0,1,.824.231.786.786,0,0,1,.271.626.835.835,0,0,1-.285.669,1.322,1.322,0,0,1-.874.246h-.351Zm1.753,5.105a2.509,2.509,0,0,1-1.351.251h-.4v-2.155h.335a2.255,2.255,0,0,1,1.358.315,1.025,1.025,0,0,1,.409.856A.85.85,0,0,1,2224.761,2030.382Z" transform="translate(-2148.991 -1957.784)" fill="#1a1a1a"/>
        <path id="Pfad_12" data-name="Pfad 12" d="M2057.792,2246.68a.572.572,0,1,0,.571.572A.572.572,0,0,0,2057.792,2246.68Z" transform="translate(-1990.163 -2173.447)" fill="#1a1a1a"/>
      </g>
    </svg>
  )
}
