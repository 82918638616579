import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './index.sass';
import App from './pages/App';
import Customer from './pages/Customer';
import Team from './pages/Team';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/customer" element={<Customer />} />
          <Route path="/team" element={<Team />} />
          <Route path="/datenschutz" element={<Team />} />
          <Route path="/impressum" element={<Team />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);
